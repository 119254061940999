import { CommonModule } from '@angular/common';
import { Component, ElementRef, AfterViewInit, input, viewChild } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'app-button-ldb',
  styleUrls: ['./button-ldb.scss'],
  templateUrl: `./button-ldb.html`,

  imports: [CommonModule, TranslateModule, IconComponent],
})
export class ButtonLeetbitComponent implements AfterViewInit {
  label = input.required<string>();
  type = input<'primary' | 'secondary' | 'tertiary' | 'accent'>('primary');
  icon = input<string>();
  width = input<number>();
  ele = viewChild<ElementRef>('ele');

  ngAfterViewInit() {
    this.width() && this.ele().nativeElement.style.setProperty('min-width', `${this.width()}px`);
  }
}
